import React from "react";
import "./index.css";

const HomePage = () => (
  <div>
    <div class="b-color">
      <div id="strip1"></div>
      <div id="col">
        <img src="/assets/logo.webp" class="logo" alt="Logo" />
        <div class="under-construction-en">Under Construction</div>
        <div class="under-construction-ar" dir="rtl">
          {`تحت الانشاء`}
        </div>
      </div>

      <div id="strip2"></div>
    </div>
  </div>
);

export default HomePage;
